.text-heading1 {
  font-size: 32px;
  line-height: 40px;
  font-family: 'Circular-Bold', sans-serif;
  font-weight: 700;
}

.text-heading2 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-family: 'Circular-Bold', sans-serif;
}

.text-heading3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 450;
  font-family: 'Circular-Medium', sans-serif;
}

.text-body-bold {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-family: 'Circular-Bold', sans-serif;
}

.text-body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 450;
  font-family: 'Circular-Book', sans-serif;
}

.text-caption {
  font-size: 12px;
  line-height: 16px;
  font-weight: 450;
  font-family: 'Circular-Book', sans-serif;
}
