.load-spinner-container {
  padding: 10rem 1rem 1rem 1rem;
  text-align: center;
}

// Spinner
.spinner {
  color: rgba(55, 59, 70, 0.5);
  height: 44px;
  width: 44px;
  fill: #e27019;
}

// Animation Spin
.animation-spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
