@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nexa';
  src: url('./assets/fonts/NexaBold/font.woff2') format('woff2'),
    url('./assets/fonts/NexaBold/font.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'National';
  src: url('./assets/fonts/NationalSemiBold/National-Semibold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'Circular-Bold';
  src: url('./assets/fonts/Circular-font-family/CircularStd-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'Circular-Black';
  src: url('./assets/fonts/Circular-font-family/CircularStd-Black.otf') format('opentype');
  font-weight: 450;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url('./assets/fonts/Circular-font-family/CircularStd-Medium.otf') format('opentype');
  font-weight: 450;
}

@font-face {
  font-family: 'Circular-Book';
  src: url('./assets/fonts/Circular-font-family/CircularStd-Book.otf') format('opentype');
  font-weight: 450;
}

@font-face {
  font-family: 'Circular-Light';
  src: url('./assets/fonts/Circular-font-family/CircularStd-Light.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Circular-Medium';
  src: url('./assets/fonts/Circular-font-family/CircularStd-Medium.otf') format('opentype');
  font-weight: 500;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-datepicker__triangle {
  transform: translate(120px, 0px) !important;
}

.border-spaced-dashed {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23DD8D58FF' stroke-width='1' stroke-dasharray='10' stroke-dashoffset='7' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.rdtPicker {
  border-radius: 16px;
  margin-top: 14px;
}

.rdtSwitch {
  font-family: 'Circular-Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 450;
  color: #373b46;
}

.rdtPrev,
.rdtNext {
  font-size: 30px !important;
  font-weight: 450;
}

.rdtOld,
.rdtNew {
  color: #848ca1;
}

.dow {
  font-family: 'Circular-Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 450;
  padding: 12px 0;
  color: #848ca1;
  width: 100%;
  text-transform: uppercase;
}

.rdtYear {
  font-family: 'Circular-Medium', sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 450;
  color: #848ca1;
  width: 100%;
  text-transform: uppercase;
  height: 32px !important;
}

.rdtActive {
  background: #d97d40 !important;
  border-radius: 16px;
  color: white;
}
