.checkbox:checked + .orange {
  border-color: #e98234;
  background-color: #e98234;
}

.checkbox:checked + .label .orange {
  left: 1rem;
  background-color: white;
}

.checkbox:checked + .label .green {
  left: 0.75rem;
  background-color: #06c270;
}
